/* global zaraz */
import React from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';
import serviceData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/mainAxiosService';

export default class ZarazConsent extends React.Component {
    constructor(props) {
        super(props);
        this.pathExtension = 'cookie-settings/';
        this.postPathExtension = 'log/cookie-consent-counter';
        this.state = {
            marketingConsent: false,
            functionalConsent: false,
            essentialConsent: true,
            isEssentialOpen: false,
            isMarketingOpen: false,
            isFunctionalOpen: false,
            isOptionsOpen: false,
            showConsentModal: false,
            publicResources: null,
            errorMsg: null,
        };
        this.getData = this.getData.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        const consentCookie = this.getCookie('zaraz-consent');
        if (!consentCookie) {
            this.getData();
        } else {
            this.loadConsentSettings();
        }
        window.addEventListener('openConsentModal', this.openModal.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('openConsentModal', this.openModal.bind(this));
    }

    getData() {
        const stateData = this.state;
        if (!stateData.publicResources) {
            this.setState({ isLoading: true });
            serviceData
                .getData(this.pathExtension)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            publicResources: res.data,
                            showConsentModal: true,
                        });
                        return;
                    }
                    reportService.reportError(res);
                })
                .catch((err) => {
                    this.setState({
                        errorMsg: err,
                        isLoading: false,
                    });
                    reportService.reportError(err);
                })
                .finally(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    }

    handleSave = () => {
        const { marketingConsent, functionalConsent } = this.state;
        if (typeof zaraz !== 'undefined' && zaraz.consent) {
            zaraz.consent.set({ KlIi: marketingConsent });
            zaraz.consent.set({ ulGI: functionalConsent });
            zaraz.set('google_consent_update', {
                ad_storage: marketingConsent ? 'granted' : 'denied',
                ad_user_data: marketingConsent ? 'granted' : 'denied',
                ad_personalization: marketingConsent ? 'granted' : 'denied',
                analytics_storage: marketingConsent ? 'granted' : 'denied',
            });
            zaraz.consent.sendQueuedEvents();
        }
        this.setCookieConsent();
        this.setState({ showConsentModal: false });
        const requestBody = { type: 'modified' };
        this.postData(requestBody);
    };

    handleRejectAll = () => {
        this.setState({ showConsentModal: false });
        if (typeof zaraz !== 'undefined' && zaraz.consent) {
            zaraz.consent.set({ KlIi: false });
            zaraz.consent.set({ ulGI: false });
            zaraz.set('google_consent_update', {
                ad_storage: 'denied',
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                analytics_storage: 'denied',
            });
        }
        this.setState({
            marketingConsent: false,
            functionalConsent: false,
        });
        this.setCookieConsent();
        const requestBody = { type: 'decline' };
        this.postData(requestBody);
    };

    handleOptions = () => {
        const { isOptionsOpen } = this.state;
        this.setState({ isOptionsOpen: !isOptionsOpen });
    }

    handleAcceptAll = () => {
        if (typeof zaraz !== 'undefined' && zaraz.consent) {
            zaraz.consent.setAll(true);
            zaraz.set('google_consent_update', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                analytics_storage: 'granted',
            });
            zaraz.consent.sendQueuedEvents();
        }
        this.setState({
            marketingConsent: true,
            functionalConsent: true,
        });
        this.setCookieConsent();
        this.setState({ showConsentModal: false });
        const requestBody = { type: 'accept' };
        this.postData(requestBody);
    };

    setCookieConsent = () => {
        document.cookie = 'zaraz-consent=true; path=/';
    };

    getCookie(name) { // eslint-disable-line class-methods-use-this
        const value = `; ${document.cookie}`;
        return value?.split(`; ${name}=`)[1]?.split(';')[0];
    }

    loadConsentSettings = () => {
        if (typeof zaraz !== 'undefined' && zaraz.consent) {
            const marketingConsent = zaraz.consent.get('KlIi');
            const functionalConsent = zaraz.consent.get('ulGI');
            this.setState({
                marketingConsent: marketingConsent || false,
                functionalConsent: functionalConsent || false,
            });
        }
    };

    openModal = () => {
        const { publicResources } = this.state;
        if (publicResources) {
            this.setState({ isOptionsOpen: true, showConsentModal: true });
        } else {
            this.getData();
        }
    };

    postData(bodyData) {
        serviceData
            .postApiData(this.postPathExtension, bodyData)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.validationErrorMessages) {
                        this.setState({
                            errorValidationMsg: res.data,
                            isFormValid: false,
                        }, () => { this.handleFeedbackErrors(); });
                        return;
                    }
                    this.setState({
                        errorValidationMsg: null,
                        postResponseData: res.data,
                    });
                    return;
                }
                reportService.reportError(res);
            })
            .catch((err) => {
                this.setState({
                    isFormValid: false,
                });
                reportService.reportError(err);
            });
    }

    toggleSection(section) {
        this.setState((prevState) => ({
            [section]: !prevState[section],
        }));
    }

    render() {
        const { marketingConsent, functionalConsent, isEssentialOpen, isMarketingOpen, isFunctionalOpen, showConsentModal, publicResources, isOptionsOpen } = this.state;

        if (!showConsentModal) {
            return null;
        }

        return (
            <>
                {publicResources && (
                    <>
                        <div
                            className="modal show d-block"
                            id="consentModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="consentModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2 id="cf_modal_title">{publicResources.mainTitle}</h2>
                                    </div>
                                    <div className="modal-body">
                                        <p className="cf_consent-intro">
                                            {publicResources.mainText}
                                        </p>
                                        <div className={`cf_consent-options ${isOptionsOpen ? 'd-block' : 'd-none'}`}>
                                            <hr />
                                            <ul className="cf_consent-container">
                                                <li className={`cf_consent-element ${isEssentialOpen ? 'cf_consent-elementBorder' : ''}`}>
                                                    <button
                                                        type="button"
                                                        onClick={() => this.toggleSection('isEssentialOpen')}
                                                        className="cf_consent-element__button"
                                                    >
                                                        <div className="cf_consent-element__checkbox-wrapper filtersModal--checkbox">
                                                            <input type="checkbox" data-purpose-id="uNFh" className="cf-checkbox" id="checkbox-1" checked={true} disabled={true} />
                                                        </div>
                                                        <div className="w-100">
                                                            <h3 className="dropdown-title">
                                                                {publicResources.essentialTitle} {isEssentialOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                                                            </h3>
                                                            {isEssentialOpen && (
                                                                <p>
                                                                    {publicResources.essentialText}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </button>
                                                </li>

                                                <li className={`cf_consent-element ${isMarketingOpen ? 'cf_consent-elementBorder' : ''}`}>
                                                    <button
                                                        type="button"
                                                        onClick={() => this.toggleSection('isMarketingOpen')}
                                                        className="cf_consent-element__button"
                                                    >
                                                        <div className="cf_consent-element__checkbox-wrapper filtersModal--checkbox">
                                                            <input
                                                                type="checkbox"
                                                                data-purpose-id="KlIi"
                                                                className="cf-checkbox"
                                                                id="checkbox-0"
                                                                checked={marketingConsent}
                                                                onChange={() => this.setState({ marketingConsent: !marketingConsent })}
                                                            />
                                                        </div>
                                                        <div className="w-100">
                                                            <h3 className="dropdown-title">
                                                                {publicResources.marketingTitle} {isMarketingOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                                                            </h3>
                                                            {isMarketingOpen && (
                                                                <p>
                                                                    {publicResources.marketingText}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </button>
                                                </li>

                                                <li className={`cf_consent-element ${isFunctionalOpen ? 'cf_consent-elementBorder' : ''}`}>
                                                    <button
                                                        type="button"
                                                        onClick={() => this.toggleSection('isFunctionalOpen')}
                                                        className="cf_consent-element__button"
                                                    >
                                                        <div className="cf_consent-element__checkbox-wrapper filtersModal--checkbox">
                                                            <input
                                                                type="checkbox"
                                                                data-purpose-id="ulGI"
                                                                className="cf-checkbox"
                                                                id="checkbox-2"
                                                                checked={functionalConsent}
                                                                onChange={() => this.setState({ functionalConsent: !functionalConsent })}
                                                            />
                                                        </div>
                                                        <div className="w-100">
                                                            <h3 className="dropdown-title">
                                                                {publicResources.functionalTitle} {isFunctionalOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                                                            </h3>
                                                            {isFunctionalOpen && (
                                                                <p>
                                                                    {publicResources.functionalText}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {isOptionsOpen ? (
                                            <>
                                                <button
                                                    type="button"
                                                    className="cf_button cf_button--save"
                                                    id="cf_consent-buttons__save"
                                                    onClick={this.handleSave}
                                                >
                                                    {publicResources.confirmButtonText}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="cf_button cf_button--reject"
                                                    id="cf_consent-buttons__reject-all"
                                                    onClick={this.handleRejectAll}
                                                >
                                                    {publicResources.rejectAllButtonText}
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                type="button"
                                                className="cf_button cf_button--options"
                                                id="cf_consent-buttons__options"
                                                onClick={this.handleOptions}
                                            >
                                                {publicResources.settingsButtonText}
                                            </button>

                                        )}
                                        <button
                                            type="button"
                                            className="cf_button cf_button--accept"
                                            id="cf_consent-buttons__accept-all"
                                            onClick={this.handleAcceptAll}
                                        >
                                            {publicResources.acceptAllButtonText}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop show" />
                    </>
                )}
            </>
        );
    }
}
