import React from 'react';
import ReactDOM from 'react-dom';
import ZarazConsent from '@PROJECT_ROOT/assets/responsive-frontend/js/services/zarazConsent';

const zarazConsent = document.getElementById('zarazConsent');

ReactDOM.render(
    <>
        <ZarazConsent />
    </>,
    zarazConsent,
);
